









































import { Component, Vue } from 'vue-property-decorator';
import TYPES from '@/types';
import GetInternetStatusService
  from '@/modules/internet-status/application/services/get-internet-status-service';
import Inject from '@/modules/shared/domain/di/inject';

@Component({ name: 'OnBoardingWelcome' })
export default class OnBoardingWelcome extends Vue {
  @Inject(TYPES.GET_INTERNET_STATUS_SERVICE)
  readonly getInternetStatusService!: GetInternetStatusService;

  get internetStatus() {
    return this.getInternetStatusService.execute();
  }
}

